import {
  Auth,
  authCustomerID,
  authFromJWTAndEmail,
  authJWT,
  loadAuthFromLocalStorage,
  loadSessionIDFromLocalStorage,
  saveAuthToLocalStorage,
} from "@trunkery/internal/lib/auth";
import { Postmate, setRemoteAuth } from "@trunkery/internal/lib/remoteAuthClient";
import { action, observable } from "mobx";
import { refreshTokenPeriodically } from "@trunkery/internal/lib/refreshToken";

function postAuthChangeCallback(auth: Auth, pm: Postmate | undefined) {
  if (typeof document !== "undefined") {
    if (auth.kind === "authorized" && auth.customerID) {
      (window as any).analytics.identify(auth.customerID);
    }
  }
  if (pm) {
    setRemoteAuth(pm, auth, loadSessionIDFromLocalStorage()); // we don't wait for completion here
  }
}

class GlobalAuthState {
  @observable.ref auth: Auth = { kind: "not_authorized" };
  remoteAuth: Postmate | undefined;

  constructor() {
    this.auth = loadAuthFromLocalStorage();
    postAuthChangeCallback(this.auth, this.remoteAuth);
  }

  refreshTokenPeriodically(shopID: string, host: string) {
    refreshTokenPeriodically({
      host,
      shopID,
      getCurrentAuth: () => this.auth,
      applyAuth: (auth) => {
        this.applyAuth(auth);
      },
    });
  }

  @action logout() {
    this.auth = { kind: "not_authorized" };
    saveAuthToLocalStorage(this.auth);
    postAuthChangeCallback(this.auth, this.remoteAuth);
  }

  @action login(token: string, email: string) {
    this.auth = authFromJWTAndEmail(token, email);
    saveAuthToLocalStorage(this.auth);
    postAuthChangeCallback(this.auth, this.remoteAuth);
  }

  @action applyAuth(auth: Auth) {
    this.auth = auth;
    saveAuthToLocalStorage(this.auth);
    postAuthChangeCallback(this.auth, this.remoteAuth);
  }

  customerIDKey() {
    return this.auth.kind === "authorized" ? `@-${this.auth.customerID}` : "not_authorized";
  }

  customerID() {
    return authCustomerID(this.auth);
  }

  jwt() {
    return authJWT(this.auth);
  }

  isLoggedIn() {
    return this.auth.kind === "authorized";
  }
}

export const globalAuthState = new GlobalAuthState();
