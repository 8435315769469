import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { graphql, useStaticQuery } from "gatsby";

export function useSiteData() {
  const data = useStaticQuery(graphql`
    query SiteDataQuery {
      siteData {
        value
      }
    }
  `);
  return data.siteData.value as RouteData.SiteData;
}
