import React from "react";
import { CSSTransition } from "react-transition-group";
import { Portal } from "@lana-commerce/core/react/components/Portal";
import { action, observable } from "mobx";
import { observer } from "mobx-react";

export interface SnackbarAction {
  label: string;
  callback: () => void;
}

export class SnackbarState {
  private hideTimeout: NodeJS.Timeout | undefined;
  @observable visible = false;
  @observable message = "";
  @observable.ref action: SnackbarAction | undefined;

  @action.bound hideMessage() {
    this.visible = false;
  }

  @action.bound showMessage(msg: string, action?: SnackbarAction) {
    this.visible = true;
    this.message = msg;
    this.action = action;
    this.resetTimeout();
    this.hideTimeout = setTimeout(this.hideMessage, 2500);
  }

  private resetTimeout() {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = undefined;
    }
  }
}

interface SnackbarProps {
  state: SnackbarState;
}

export const Snackbar = observer((props: SnackbarProps) => {
  const { state } = props;
  return (
    <Portal>
      <CSSTransition mountOnEnter unmountOnExit in={state.visible} timeout={250}>
        <div className="snackbar">
          <div className="snackbar-body">
            <div className="snackbar-body__message">{state.message}</div>
            {state.action ? (
              <div onClick={state.action.callback} className="snackbar-body__action">
                {state.action.label}
              </div>
            ) : null}
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
});
