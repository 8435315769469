import React from "react";
import { PaypalBackdropState } from "utils/globalPaypalBackdropState";
import { Portal } from "@lana-commerce/core/react/components/Portal";
import { observer } from "mobx-react";

import { T } from "./PaypalBackdrop.tlocale";

interface PaypalBackdropProps {
  state: PaypalBackdropState;
}

@observer
export class PaypalBackdrop extends React.Component<PaypalBackdropProps> {
  render() {
    const { state } = this.props;
    return state.visible ? (
      <Portal>
        <div className="backdrop">{T("Performing PayPal Express Checkout...")}</div>
      </Portal>
    ) : null;
  }
}
