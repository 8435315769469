import React from "react";
import { Dialog } from "components/Dialog";
import { EnvironmentContext } from "@trunkery/internal/lib/environmentContext";
import { PaypalBackdrop } from "components/PaypalBackdrop";
import { Snackbar } from "components/Snackbar";
import { environmentFromSiteData } from "utils/environmentFromSiteData";
import { getRemoteSSRFriendly } from "@trunkery/internal/lib/remoteAuthClient";
import { globalAuthState } from "utils/globalAuthState";
import { globalDialogState } from "utils/globalDialogState";
import { globalPaypalBackdropState } from "utils/globalPaypalBackdropState";
import { globalSnackbarState } from "utils/globalSnackbarState";
import { observer } from "mobx-react";
import { shopRemoteURL } from "@lana-commerce/core/shopLinks";
import { useSiteData } from "utils/useSiteData";

interface EnvironmentProviderProps {
  children: React.ReactNode;
}

export const EnvironmentProvider = observer((props: EnvironmentProviderProps) => {
  const siteData = useSiteData();
  const env = environmentFromSiteData(siteData);
  globalAuthState.remoteAuth = getRemoteSSRFriendly(shopRemoteURL(env.shopDomain)); // preload remote auth iframe
  globalAuthState.refreshTokenPeriodically(env.shopID, env.host);
  return (
    <>
      <EnvironmentContext.Provider value={env}>
        <React.Fragment key={globalAuthState.customerIDKey()}>{props.children}</React.Fragment>
        <Snackbar state={globalSnackbarState} />
        <Dialog state={globalDialogState} />
        <PaypalBackdrop state={globalPaypalBackdropState} />
      </EnvironmentContext.Provider>
    </>
  );
});
