import React, { useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { Portal } from "@lana-commerce/core/react/components/Portal";
import { RootCloseWrapper } from "@lana-commerce/core/react/components/RootCloseWrapper";
import { action, observable } from "mobx";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { observer } from "mobx-react";

export type DialogResult<T> = { kind: "ok"; data: T } | { kind: "cancel" };

export interface DialogRenderArgs<T> {
  setTitle(v: string): void;
  ok(result: T): void;
  cancel(): void;
}

type ResolveFn = (value: DialogResult<any> | PromiseLike<DialogResult<any>>) => void;
type RenderFn = (args: DialogRenderArgs<any>) => JSX.Element;

export class DialogState {
  @observable.ref resolve: ResolveFn | undefined;
  @observable.ref promise: Promise<any> | undefined;
  @observable.ref renderFn: RenderFn | undefined;
  @observable title = "";
  @observable visible = false;

  @action.bound hideDialog() {
    this.visible = false;
  }

  @action.bound showDialog<T = any>(
    title: string,
    fn: (args: DialogRenderArgs<T>) => JSX.Element
  ): Promise<DialogResult<T>> {
    this.title = title;
    this.visible = true;
    this.promise = new Promise<DialogResult<T>>((resolve) => {
      this.resolve = resolve;
    });
    this.renderFn = fn;
    return this.promise;
  }

  @action.bound ok(v: any) {
    this.visible = false;
    if (this.resolve) {
      this.resolve({ kind: "ok", data: v });
    }
  }

  @action.bound cancel() {
    this.visible = false;
    if (this.resolve) {
      this.resolve({ kind: "cancel" });
    }
  }

  @action.bound setTitle(v: string) {
    this.title = v;
  }
}

interface DialogProps {
  state: DialogState;
}

export const Dialog = observer((props: DialogProps) => {
  const { state } = props;
  const dialogContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const el = dialogContainerRef.current;
    if (el) {
      if (state.visible) {
        disableBodyScroll(el, { reserveScrollBarGap: true });
      } else {
        enableBodyScroll(el);
      }
    }
  }, [state.visible]);
  const args: DialogRenderArgs<any> = {
    ok: state.ok,
    cancel: state.cancel,
    setTitle: state.setTitle,
  };
  return (
    <Portal>
      <CSSTransition mountOnEnter unmountOnExit in={state.visible} timeout={150}>
        <div ref={dialogContainerRef} className="dialog-container">
          <RootCloseWrapper onRootClose={state.cancel}>
            <div className="dialog">
              <div className="dialog__header">
                <div className="dialog__title">{state.title}</div>
                <div className="dialog__close" onClick={state.cancel}>
                  <img src={require("images/cross-big.svg").default} />
                </div>
              </div>
              <div className="dialog__body">{state.renderFn?.(args) || null}</div>
            </div>
          </RootCloseWrapper>
        </div>
      </CSSTransition>
    </Portal>
  );
});
