import { Environment } from "@trunkery/internal/lib/environment";
import { RouteData } from "@trunkery/internal/lib/vatureTypes";
import { getCartFromLocalStorage } from "@trunkery/internal/lib/cartLocalStorage";
import { getRemote, setRemoteAuth } from "@trunkery/internal/lib/remoteAuthClient";
import { loadAuthFromLocalStorage, loadSessionIDFromLocalStorage } from "@trunkery/internal/lib/auth";
import { shopCheckoutOrderURL, shopRemoteURL } from "@lana-commerce/core/shopLinks";
import { shopDomain } from "@lana-commerce/core/shopDomain";

export function environmentFromSiteData(siteData: RouteData.SiteData): Environment {
  const { config, shop, currencies, paypalGateway: paypal, stripeGateway: stripe } = siteData;
  const env: Environment = {
    locale: shop.language?.code || "",
    cdn: config.cdn,
    host: config.host,
    shopID: shop.id,
    shopName: shop.name,
    shopDomain: shopDomain(shop),
    shopCurrency: shop.currency,
    shopCountry: shop.shop_address.country?.code || "",
    shopCustomerServiceEmail: shop.customer_service_email,
    stripeGatewayID: stripe?.id || "",
    stripeAccountID: stripe?.stripe?.account_id || "",
    stripePublishableKey: stripe?.stripe?.publishable_key || "",
    stripeCardTokenization: stripe?.stripe?.card_tokenization || false,
    stripeTestMode: stripe?.test_mode || false,
    paypalExpressCheckout: !!paypal,
    googleMapsAPIKey: "", // TODO
    reviewsEnabled: shop.product_review_enabled,
    baseURL: config.baseURL,
    authChange: (_token: string, _email: string) => {}, // TODO
    goToCheckout: (search?: string) => {
      const cart = getCartFromLocalStorage();
      if (!cart) return;

      setRemoteAuth(
        getRemote(shopRemoteURL(env.shopDomain)),
        loadAuthFromLocalStorage(),
        loadSessionIDFromLocalStorage()
      ).then((authResult) => {
        if (authResult === "timeout") console.error("set remote auth timeout");
        document.location.href = `${shopCheckoutOrderURL(env.shopDomain, cart.id, cart.uuid)}${search || ""}`;
      });
    },
    analytics: (method: string, ...args: any[]) => {
      const w = window as any;
      if (w.analytics) w.analytics[method](...args);
    },
    currencies,
  };
  return env;
}
